import { EOrganizationClassification, EOrganizationType } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { ETrackingEvent } from '~@types/tracking';
import { EUserDetail } from '~@types';
import { POLLING_CONFIG_MEDIUM } from '~@constants/polling';
import { BASE_PATH as ROLES_BASE_PATH, filterRoles } from '~hooks/useRoles';
import { SWR_CONFIG_RETRY_MEDIUM } from '~@constants/swr';
import { appendQueryParams } from '~utils/urls';
import { formatCardMask } from '~utils/formats';
import { isCardExpired, isCardExpiring } from '~utils/payment';
import { useAuth } from '~contexts/Auth';
import { useNavigate } from 'react-router-dom';
import ApiError from '~classes/ApiError';
import IUser, { EUserMode, EUserPaymentType, EUserStatus } from '~interfaces/User';
import IUserRole, { EPermissionAction, EPermissionTarget, ERoleStatus } from '~interfaces/UserRoles';
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import UserSettings from '~interfaces/UserSettings';
import useAnalytics from '~hooks/useAnalytics';
import useApi, { useApiPolling } from '~contexts/Api';
import useLocalState from '~hooks/useLocalState';
import usePaymentCards from '~hooks/usePaymentCards';
import useSWR, { SWRResponse, mutate } from 'swr';

export enum EUserCreateError {
    ORGANIZATION_PHONE_EXISTS = 'ORGANIZATION_PHONE_EXISTS',
    USER_PHONE_EXISTS = 'USER_PHONE_EXISTS',
}

export type UserCreatePayload = Partial<
    Omit<IUser, 'userSettings'> & {
        userSettings: Partial<UserSettings>;
    }
>;

export type UserCreateOptions = {
    invitationId?: string;
    verificationCode?: string;
};

export type UserUpdatePayload = Partial<
    Omit<IUser, 'userSettings'> & {
        userSettings: Partial<UserSettings>;
    }
>;

interface IUserContext {
    activeOrganizationId?: string;
    activePaymentMethod: string | null;
    activeRole?: IUserRole;
    additionalRoles?: IUserRole[];
    additionalRolesCount: number;
    alternateRole?: IUserRole;
    changeRole: (role: IUserRole) => void;
    changeRoleById: (roleId: string) => void;
    createUser: (user: UserCreatePayload, options?: UserCreateOptions) => Promise<void>;
    deleteUser: () => Promise<void>;
    family?: IUserRole; // user has a family if they are the admin of one regardless of its status
    hasExpiredPaymentCard?: boolean;
    hasExpiringPaymentCard?: boolean;
    hasPaymentMethod: boolean | null;
    isActiveUser: boolean;
    isAddressRequired?: boolean;
    isAuthenticated: boolean | null;
    isBlocked?: boolean;
    isDeleting?: boolean;
    isFamilyAdmin: boolean;
    isReady: boolean;
    isRegistered: boolean | null;
    isRoleActive: (role: IUserRole) => boolean;
    merUser?: IUser | null;
    missingDetails?: EUserDetail[];
    mutateUser?: SWRResponse<IUser | void, ApiError>['mutate'];
    personalRole?: IUserRole;
    roles?: IUserRole[];
    rolesCount: number;
    setActiveRoleId: (id: string | undefined) => void;
    updateUser: (user: UserUpdatePayload) => Promise<void>;
    userMode: EUserMode;
}

type UserProviderProps = {
    children: ReactNode;
};

type UserCondition = boolean | null;

const UserContext = createContext<IUserContext>({
    activePaymentMethod: null,
    additionalRolesCount: 0,
    hasPaymentMethod: null,
    isActiveUser: true,
    isAuthenticated: null,
    isFamilyAdmin: false,
    isReady: false,
    isRegistered: null,
    roles: [],
    rolesCount: 0,
    userMode: EUserMode.USER,
    updateUser: () => {
        throw new Error('updateUser has not been implemented');
    },
    createUser: () => {
        throw new Error('createUser has not been implemented');
    },
    deleteUser: () => {
        throw new Error('deleteUser has not been implemented');
    },
    setActiveRoleId: () => {
        throw new Error('setActiveRoleId has not been implemented');
    },
    changeRole: () => {
        throw new Error('changeRole has not been implemented');
    },
    changeRoleById: () => {
        throw new Error('changeRoleById has not been implemented');
    },
    isRoleActive: () => {
        throw new Error('isRoleActive has not been implemented');
    },
});

export const SMB_AUTOMATED_ROLE_ID = 'smb-automated-role-id';
const SMB_ROLE_DEFAULTS: IUserRole = {
    roleId: ERoleId.SMB,
    userRoleId: 'smb-default-role-id',
    roleName: 'Administrator',
    organizationId: '',
    organizationName: '',
    organizationType: EOrganizationType.BUSINESS,
    organizationClassification: EOrganizationClassification.COMPANY,
    status: ERoleStatus.ACTIVE,
    permissions: [
        {
            target: EPermissionTarget.BENEFIT,
            action: EPermissionAction.GET,
        },
        {
            target: EPermissionTarget.BENEFIT,
            action: EPermissionAction.POST,
        },
        {
            target: EPermissionTarget.CDR,
            action: EPermissionAction.GET,
        },
    ],
};

const USER_ROLE_DEFAULTS: IUserRole = {
    roleId: ERoleId.USER,
    userRoleId: 'user-default-role-id',
    roleName: 'User',
    organizationId: '',
    organizationName: '',
    organizationType: EOrganizationType.USER,
    organizationClassification: EOrganizationClassification.PRIVATE,
    status: ERoleStatus.ACTIVE,
    permissions: [],
};

function UserProvider(props: UserProviderProps): JSX.Element {
    const { isAuthenticated, isReady: isAuthReady } = useAuth();
    const { cards, loading: cardsLoading } = usePaymentCards();
    const { get, patch, post, del } = useApi();
    const { poll } = useApiPolling();
    const { trackGA4Event } = useAnalytics();
    const {
        state: { activeRoleId, lastAlternativeRoleId },
        actions: { setActiveRoleId, setLastAlternativeRoleId },
    } = useLocalState();
    const navigate = useNavigate();

    /*
        USER
     */
    const {
        data: merUser = null,
        // error: userError, // TODO: Tim: tie this into the error handling system
        mutate: mutateUser,
        isValidating: isMerUserLoading,
    } = useSWR<IUser | void, ApiError>(isAuthReady && isAuthenticated ? `/users/me` : null, get, {
        revalidateOnFocus: false,
        errorRetryCount: 0,
    });

    const isRegistered: UserCondition = useMemo(() => {
        if (!isAuthReady) return null;
        if (!isAuthenticated) return false;
        // TIM: must check isMerUserLoading is false, there is a weird problem here.
        if (isMerUserLoading !== false && !merUser) return null;
        return !!merUser;
    }, [merUser, isAuthReady, isAuthenticated, isMerUserLoading]);

    const createUser: IUserContext['createUser'] = useCallback(
        async (user, options = {}) => {
            await post(appendQueryParams(`/users`, options), user);
            // If there was an invitationId check that the new user gets a role
            if (options?.invitationId)
                await poll<IUserRole[]>(
                    '/users/me/roles',
                    {},
                    (_error, result) => {
                        return !!result?.length;
                    },
                    POLLING_CONFIG_MEDIUM.maxAttempts,
                    POLLING_CONFIG_MEDIUM.delay,
                );

            await mutateUser();
            await mutate('/users/me/roles');
            trackGA4Event(ETrackingEvent.REGISTER_USER);
        },
        [mutateUser, poll, post, trackGA4Event],
    );

    const updateUser: IUserContext['updateUser'] = useCallback(
        async (user) => {
            await patch('/users/me', user);
            await mutateUser();
        },
        [mutateUser, patch],
    );

    const deleteUser: IUserContext['deleteUser'] = useCallback(async () => {
        await del('/users/me');
        await mutateUser();
    }, [del, mutateUser]);

    /*
        ROLES
     */

    // We fetch roles directly from user context to avoid a circular dependency
    const { data: rolesData, isValidating: rolesLoading } = useSWR<IUserRole[] | void, ApiError>(
        isAuthenticated && (isRegistered ?? false) ? ROLES_BASE_PATH : null,
        get,
        SWR_CONFIG_RETRY_MEDIUM,
    );

    const userRole: IUserRole | undefined = useMemo(() => {
        // If it is a smb user, create user role as smb
        if (
            merUser?.accountClassification &&
            [EOrganizationClassification.COMPANY, EOrganizationClassification.COOPERATIVE].includes(
                merUser?.accountClassification,
            )
        )
            return {
                ...SMB_ROLE_DEFAULTS,
                organizationId: merUser.accountNumber ?? 'missing-org-id',
                organizationName: merUser.firstName,
                organizationClassification: merUser?.accountClassification,
            };
        // otherwise create user role as private user
        else if (merUser)
            return {
                ...USER_ROLE_DEFAULTS,
                organizationId: merUser.accountNumber ?? 'missing-org-id',
                organizationName: merUser.firstName,
                organizationClassification: merUser?.accountClassification,
            };
    }, [merUser]);

    const roles = useMemo(() => {
        const roles = filterRoles(rolesData) ?? [];
        if (!roles.length && userRole) return [userRole];
        return roles;
    }, [rolesData, userRole]);

    const changeRole = useCallback(
        (role: IUserRole) => {
            setActiveRoleId(role.userRoleId);
            switch (role.roleId) {
                case ERoleId.ADMINISTRATOR:
                case ERoleId.SMB: {
                    navigate('/organization');
                    break;
                }
                default: {
                    navigate('/');
                }
            }
        },
        [navigate, setActiveRoleId],
    );

    const changeRoleById = useCallback(
        (roleId: string) => {
            const role = roles?.find((r) => r.userRoleId === roleId);
            if (role) changeRole(role);
            else console.warn('changeRoleById: role not found', roleId);
        },
        [roles, changeRole],
    );

    // useEffect(() => {
    //     // Automatically set automated smb role as active if it exists
    //     if (smbRole) setActiveRoleId(smbRole.userRoleId);
    // }, [setActiveRoleId, smbRole]);

    // useEffect(() => {
    //     // set activeRoleId if the active role has been chosen automatically above
    //     if (activeRole && !activeRoleId) setActiveRoleId(activeRole.userRoleId);
    // }, [activeRole, activeRoleId, setActiveRoleId]);

    // Set up the basic account role, seen as the personal account role
    const personalRole = useMemo(
        () =>
            roles?.find((role) => {
                // if is SMB role, treat it as personal
                if (role.roleId === ERoleId.SMB) return true;
                // if is MEMBER role, treat is as personal as user can only have one membership
                if (role.roleId === ERoleId.MEMBER) return true;
                // if is resident role, treat it as personal as user can only have one resident role
                if (role.roleId === ERoleId.RESIDENT) return true;
                // if is USER role, treat it as personal
                if (role.roleId === ERoleId.USER) return true;
                // if is admin of family, treat is as personal
                if (
                    role.roleId === ERoleId.ADMINISTRATOR &&
                    role.organizationClassification === EOrganizationClassification.FAMILY
                )
                    return true;
                return false;
            }),
        [roles],
    );

    const activeRole = useMemo(() => {
        // If there is an activeRoleId use it
        if (activeRoleId) return roles?.find((r) => r.userRoleId === activeRoleId);
        // If there is a personal role, and no alternate role, meaning there is only a single role or no roles
        // use the personal role
        if (personalRole !== undefined && roles.length <= 1) return personalRole;
    }, [activeRoleId, personalRole, roles]);

    // If there are additional roles, or the active role is not the personal role set an alternative role
    const alternateRole = useMemo(
        () =>
            activeRole && activeRole.userRoleId !== personalRole?.userRoleId
                ? activeRole
                : roles?.find((role) =>
                      lastAlternativeRoleId && lastAlternativeRoleId !== personalRole?.userRoleId
                          ? role.userRoleId === lastAlternativeRoleId
                          : role.userRoleId !== personalRole?.userRoleId,
                  ),
        [activeRole, lastAlternativeRoleId, personalRole?.userRoleId, roles],
    );

    const additionalRoles = roles?.filter(
        (role) => role.userRoleId !== personalRole?.userRoleId && role.userRoleId !== alternateRole?.userRoleId,
    );

    const rolesCount = roles?.length ?? 0;
    const additionalRolesCount = additionalRoles?.length ?? 0;

    const isRoleActive = useCallback(
        (role: IUserRole) => {
            return role.userRoleId === activeRole?.userRoleId;
        },
        [activeRole?.userRoleId],
    );

    useEffect(() => {
        if (
            !!activeRole?.userRoleId &&
            activeRole?.userRoleId === alternateRole?.userRoleId &&
            activeRole?.userRoleId !== lastAlternativeRoleId
        )
            setLastAlternativeRoleId(activeRole?.userRoleId);
    }, [activeRole, alternateRole?.userRoleId, lastAlternativeRoleId, setLastAlternativeRoleId]);

    useEffect(() => {
        // Clear activeRoleId if it doesn't match the users roles
        if (roles?.length && !rolesLoading && activeRoleId && !roles.find((r) => r.userRoleId === activeRoleId))
            setActiveRoleId(undefined);
    }, [roles, rolesLoading, activeRoleId, setActiveRoleId]);

    const family = useMemo(
        () =>
            activeRole &&
            activeRole.organizationClassification === EOrganizationClassification.FAMILY &&
            activeRole.roleId === ERoleId.ADMINISTRATOR
                ? activeRole
                : roles?.find(
                      (r) =>
                          r.organizationClassification === EOrganizationClassification.FAMILY &&
                          r.roleId === ERoleId.ADMINISTRATOR,
                  ),
        [activeRole, roles],
    );

    const activeOrganizationId = useMemo(
        () =>
            activeRole && [ERoleId.ADMINISTRATOR, ERoleId.SMB].includes(activeRole.roleId)
                ? activeRole.organizationId
                : undefined,
        [activeRole],
    );

    const userMode = useMemo(() => {
        const { roleId, organizationClassification } = activeRole || {};
        switch (organizationClassification) {
            case EOrganizationClassification.FAMILY: {
                switch (roleId) {
                    case ERoleId.ADMINISTRATOR:
                        return EUserMode.FAMILY_ADMIN;
                    case ERoleId.USER:
                    case ERoleId.MEMBER:
                        return EUserMode.FAMILY_MEMBER;
                }
                break;
            }
            case EOrganizationClassification.COOPERATIVE: {
                switch (roleId) {
                    case ERoleId.SMB:
                        return EUserMode.SMB;
                    case ERoleId.ADMINISTRATOR:
                        return EUserMode.COOPERATIVE_ADMIN;
                    case ERoleId.USER:
                    case ERoleId.MEMBER:
                        return EUserMode.COOPERATIVE_MEMBER;
                }
                break;
            }
            case EOrganizationClassification.COMPANY:
            case EOrganizationClassification.PRIVATE: {
                switch (roleId) {
                    case ERoleId.SMB:
                        return EUserMode.SMB;
                    case ERoleId.ADMINISTRATOR:
                        return EUserMode.COOPERATIVE_ADMIN;
                    case ERoleId.USER:
                        // TIM: this was changed to support the role based approach, hopefully with no consequences
                        return EUserMode.USER;
                    case ERoleId.MEMBER:
                        return EUserMode.COOPERATIVE_MEMBER;
                }
                break;
            }
            default:
                return EUserMode.USER;
        }
        return EUserMode.USER;
    }, [activeRole]);

    /*
        READY STATE
     */

    const isReady = useMemo(() => {
        return isAuthReady && isRegistered !== null && (isRegistered ? roles !== undefined : true);
    }, [isAuthReady, isRegistered, roles]);

    /*
        USER / ROLE conditional props
     */

    const hasPaymentMethod: UserCondition = useMemo(() => {
        if (!isReady || !merUser) return null;
        if (isRegistered === null) return null;
        if (!isRegistered) return false;

        if (activeRole && activeRole.roleId === ERoleId.MEMBER) return true;

        switch (merUser?.paymentType) {
            case EUserPaymentType.NONE: {
                return false;
            }
            case EUserPaymentType.E_INVOICE: {
                return true;
            }
            case EUserPaymentType.CARD: {
                return !cardsLoading && cards !== null ? !!cards?.length : null;
            }
            default: {
                return false;
            }
        }
    }, [activeRole, cards, cardsLoading, isReady, isRegistered, merUser]);

    const activePaymentMethod = useMemo(() => {
        if (!hasPaymentMethod) return null;
        switch (merUser?.paymentType) {
            case EUserPaymentType.E_INVOICE:
                return merUser.email;
            case EUserPaymentType.CARD:
                return cards?.length ? formatCardMask(cards[0].CardMask) : null;
            default:
                return null;
        }
    }, [hasPaymentMethod, merUser, cards]);

    const [hasExpiringPaymentCard, hasExpiredPaymentCard]: (boolean | undefined)[] = useMemo(() => {
        if (merUser?.paymentType !== EUserPaymentType.CARD || cardsLoading || cards === null)
            return [undefined, undefined];

        return [isCardExpiring(cards?.[0]), isCardExpired(cards?.[0])];
    }, [cards, cardsLoading, merUser?.paymentType]);

    const missingDetails = useMemo(() => {
        if (!merUser || !isReady) return undefined;
        const missing: EUserDetail[] = [];
        if (hasPaymentMethod === false) missing.push(EUserDetail.PAYMENT);
        if (
            !merUser.privateAddress?.addressLine1 ||
            !merUser.privateAddress?.city ||
            !merUser.privateAddress?.postalCode ||
            !merUser.privateAddress?.country
        )
            missing.push(EUserDetail.ADDRESS);

        return missing.length ? missing : undefined;
    }, [hasPaymentMethod, isReady, merUser]);

    const isAddressRequired = useMemo(() => {
        return activeRole?.roleId === ERoleId.MEMBER
            ? false
            : missingDetails?.includes(EUserDetail.ADDRESS) &&
                  [
                      EUserPaymentType.EHF,
                      EUserPaymentType.E_INVOICE,
                      EUserPaymentType.KLARNA,
                      EUserPaymentType.POSTAL,
                      EUserPaymentType.CARD,
                  ].includes(merUser?.paymentType ?? EUserPaymentType.NONE);
    }, [activeRole?.roleId, merUser?.paymentType, missingDetails]);

    const isFamilyAdmin = useMemo(() => userMode === EUserMode.FAMILY_ADMIN, [userMode]);

    const isActiveUser = useMemo(() => {
        return merUser?.status === EUserStatus.ACTIVE;
    }, [merUser]);

    const isBlocked = useMemo(() => merUser?.status === EUserStatus.BLOCKED, [merUser]);
    const isDeleting = useMemo(() => merUser?.status === EUserStatus.DELETE_REQUESTED, [merUser]);

    const context: IUserContext = useMemo(
        () => ({
            activeOrganizationId,
            activePaymentMethod,
            activeRole,
            additionalRoles,
            additionalRolesCount,
            alternateRole,
            changeRole,
            changeRoleById,
            createUser,
            deleteUser,
            family,
            hasExpiredPaymentCard,
            hasExpiringPaymentCard,
            hasPaymentMethod,
            isActiveUser,
            isAddressRequired,
            isAuthenticated,
            isBlocked,
            isDeleting,
            isFamilyAdmin,
            isReady,
            isRegistered,
            isRoleActive,
            merUser,
            missingDetails,
            mutateUser,
            personalRole,
            roles,
            rolesCount,
            setActiveRoleId,
            updateUser,
            userMode,
        }),
        [
            activeOrganizationId,
            activePaymentMethod,
            activeRole,
            additionalRoles,
            additionalRolesCount,
            alternateRole,
            changeRole,
            changeRoleById,
            createUser,
            deleteUser,
            family,
            hasExpiredPaymentCard,
            hasExpiringPaymentCard,
            hasPaymentMethod,
            isActiveUser,
            isAddressRequired,
            isAuthenticated,
            isBlocked,
            isDeleting,
            isFamilyAdmin,
            isReady,
            isRegistered,
            isRoleActive,
            merUser,
            missingDetails,
            mutateUser,
            personalRole,
            roles,
            rolesCount,
            setActiveRoleId,
            updateUser,
            userMode,
        ],
    );

    return <UserContext.Provider value={context} {...props} />;
}

function useUser(): IUserContext {
    const context = useContext(UserContext);
    if (!context) throw new Error('useUser must be used within a UserProvider');

    return context;
}

export { UserProvider, useUser };
